import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PiPhoneDisconnect } from 'react-icons/pi';
import cn from 'classnames';
import { ExitVideoRoom, Tooltip } from 'components';
import style from './controls.module.scss';

type ExitProps = {
  setIsActiveMeeting: React.Dispatch<React.SetStateAction<boolean>>;
  setPause: React.Dispatch<React.SetStateAction<boolean>>;
};

export const Exit: FC<ExitProps> = ({ setIsActiveMeeting, setPause }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'meeting' });
  const [isOpenModal, setIsOpenModal] = useState(false);

  const handleOpenModal = () => setIsOpenModal(true);
  const handleCloseModal = () => setIsOpenModal(false);

  return (
    <>
      <Tooltip id='controls.exit' content={t('controls.exit')} />
      <button
        className={cn(style.button, style.button__phoneDown)}
        data-tooltip-id='controls.exit'
        onClick={handleOpenModal}
      >
        <PiPhoneDisconnect size='25px' color='white' />
      </button>
      {isOpenModal && <ExitVideoRoom onClose={handleCloseModal} setPause={setPause} />}
    </>
  );
};
