import React, { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import cn from 'classnames';
import { useGetMe } from 'api';
import { Button } from 'components';
import { QUERY } from 'constants/path';
import style from './role-selection-block.module.scss';

//Для дома в будущем каждая роль будет вынесина в отдельный компонент
const ROLES_DATA = [
  {
    title: 'roles.engineer.title',
    buttonTitle: 'roles.engineer.buttonTitle',
    description: 'roles.engineer.description',
    count: 0,
  },
  {
    title: 'roles.expert.title',
    buttonTitle: 'roles.expert.buttonTitle',
    description: 'roles.expert.description',
    count: 1,
  },
  {
    title: 'roles.observer.title',
    buttonTitle: 'roles.observer.buttonTitle',
    description: 'roles.observer.description',
    count: 2,
  },
  {
    title: 'roles.security.title',
    buttonTitle: 'roles.security.buttonTitle',
    description: 'roles.security.description',
    count: 1,
  },
] as const;

type RoleSelectionBlockProps = {
  setIsActiveMeeting: React.Dispatch<React.SetStateAction<boolean>>;
};

export const RoleSelectionBlock: FC<RoleSelectionBlockProps> = ({ setIsActiveMeeting }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'meeting' });
  const [query] = useSearchParams();
  const headsetId = query.get(QUERY.headsetId);
  const { data: user } = useGetMe();

  const handleStart = useCallback(() => {
    setIsActiveMeeting(true);
  }, [setIsActiveMeeting]);

  const isDisabled = !user;

  const display = useMemo(
    () =>
      ROLES_DATA.map(({ title, count, buttonTitle, description }) => (
        <div className={style.item} key={title}>
          <div className={style.title}>{t(title, { count })}</div>
          <Button
            classNames={{ button: style.button }}
            label={t(buttonTitle)}
            isDisabled={isDisabled}
            onClick={handleStart}
          />
          <div className={style.description}>{t(description)}</div>
        </div>
      )),
    [isDisabled, handleStart],
  );

  const isMyHeadset = user?.headset?.id === headsetId;

  return (
    <div className={style.block}>
      {isMyHeadset ? (
        <div className={cn(style.item, style.item__myHeadset)}>
          <Button
            classNames={{ button: style.button }}
            label={t('roles.headset.buttonTitle')}
            isDisabled={isDisabled}
            onClick={handleStart}
          />
          <div className={style.description}>{t('roles.headset.description')}</div>
        </div>
      ) : (
        display
      )}
    </div>
  );
};
